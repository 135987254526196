const _import = require('./import-production')

export default[
    {path: '/across', 
    component: _import('common/across_template'),
        redirect: { name: 'PsInfoJump' },
    children: [
        {path: 'PsInfoJump', component: _import('personal_module/ps_info/ps_information'), name: 'PsInfoJump', meta: {title: '个人信息表', isTab: false}},
        {path: 'ExamReportJump', component: _import('personal_module/records/report'), name: 'ExamReportJump', meta: {title: '综合体检报告', isTab: true}},
        {path: 'SanCeDanJump', component: _import('personal_module/records/SanCeDan/chartView'), name: 'SanCeDanJump', meta: {title: '三测单', isTab: true}},
        {
            path: 'HistorySingoJump1', component: _import('personal_module/records/History/HistorySingo'), name: 'HistorySingoJump1', meta: {title: '单导联心电检测记录', isTab: true, showfater: true},
            children:[{path: 'HistorySingo2Jump1', component: _import('personal_module/records/History/HistorySingo2'), name: 'HistorySingo2Jump1', meta: {title: '页面', isTab: false, showfater: false}},
            ]
        },
        {
            path: 'HistorySingoHolterJump1', component: _import('personal_module/records/History/HistorySingoHolter'), name: 'HistorySingoHolterJump1', meta: {title: '单导联Holter检测记录', isTab: false, showfater: false},
            children:[{path: 'HistorySingoHolter2Jump1', component: _import('personal_module/records/History/HistorySingoHolter2'), name: 'HistorySingoHolter2Jump1', meta: {title: '页面', isTab: false, showfater: false}},
                {path: 'holterReportJump1', component: _import('personal_module/records/holter_report'), name: 'holterReportJump1', meta: {title: '动态报告', isTab: false, showfater: false}}]
        },
        {
            path: 'HistoryPhotoJump', component: _import('personal_module/records/History/HistoryPhoto'), name: 'HistoryPhotoJump', meta: {title: '其他报告', isTab: false, showfater: false},
        },
        {path: 'ViewMonthlyReport', component: _import('doctor_station/vip/ViewMonthlyReport'), name: 'ViewMonthlyReport', meta: {title: 'VIP每月报告', isTab: true}},
        {path: 'TreatReportAcross', component: _import('personal_module/remote/ViewTreatReport'), name: 'TreatReportAcross', meta: {title: '诊疗报告', isTab: false}},
        {path: 'TreatHostAcross', component: _import('user_treat/host'), name: 'TreatHostAcross', meta: {title: '远程诊疗', isTab: false}},
        {path: 'TreatInviteAcross', component: _import('user_treat/invite'), name: 'TreatInviteAcross', meta: {title: '远程诊疗', isTab: false},
            children: [{path: 'across2',
                component: _import('common/across_second'),
                children: [
                    {
                        path: 'UserSingoTreat', component: _import('personal_module/records/History/HistorySingo'), name: 'UserSingoTreat', meta: {title: '单导联心电检测记录', isTab: false, showfater: true},
                        children:[{path: 'UserSingo2Treat', component: _import('personal_module/records/History/HistorySingo2'), name: 'UserSingo2Treat', meta: {title: '页面', isTab: false, showfater: false}},
                        ]
                    },
                    {
                        path: 'UserSingo2Direct', component: _import('personal_module/records/History/HistorySingo2'), name: 'UserSingo2Direct', meta: {title: '单导联心电检测记录', isTab: false, showfater: false},
                    },
                    {
                        path: 'UserSingoHolterTreat', component: _import('personal_module/records/History/HistorySingoHolter'), name: 'UserSingoHolterTreat', meta: {title: '单导联Holter检测记录', isTab: false, showfater: true},
                        children:[{path: 'UserSingoHolter2Treat', component: _import('personal_module/records/History/HistorySingoHolter2'), name: 'UserSingoHolter2Treat', meta: {title: '页面', isTab: false, showfater: false}},
                            {path: 'holterReportUser', component: _import('personal_module/records/holter_report'), name: 'holterReportUser', meta: {title: '动态报告', isTab: false, showfater: false}}]
                    },
                    {
                        path: 'UserPhotoTreat', component: _import('personal_module/records/report'), name: 'UserPhotoTreat', meta: {title: '其他报告', isTab: false, showfater: true},
                    },
                ],
            }],},

        {path: 'ChaXunAcross', component: _import('common/TiJianChaXun'), name: 'ChaXunAcross', meta: {title: '体检查询', isTab: false},
            children: [
                {path: 'across3',
                    component: _import('common/across_third'),
                    children: [
                        {
                            path: 'UserSingoTreat3', component: _import('personal_module/records/History/HistorySingo'), name: 'UserSingoTreat3', meta: {title: '单导联心电检测记录', isTab: false, showfater: true},
                            children:[{path: 'UserSingo2Treat3', component: _import('personal_module/records/History/HistorySingo2'), name: 'UserSingo2Treat3', meta: {title: '页面', isTab: false, showfater: false}},
                            ]
                        },
                        {
                            path: 'UserSingo2Direct3', component: _import('personal_module/records/History/HistorySingo2'), name: 'UserSingo2Direct3', meta: {title: '单导联心电检测记录', isTab: false, showfater: false},
                        },
                        {
                            path: 'UserSingoHolterTreat3', component: _import('personal_module/records/History/HistorySingoHolter'), name: 'UserSingoHolterTreat3', meta: {title: '单导联Holter检测记录', isTab: false, showfater: true},
                            children:[{path: 'UserSingoHolter2Treat3', component: _import('personal_module/records/History/HistorySingoHolter2'), name: 'UserSingoHolter2Treat3', meta: {title: '页面', isTab: false, showfater: false}},
                                {path: 'holterReportUser3', component: _import('personal_module/records/holter_report'), name: 'holterReportUser3', meta: {title: '动态报告', isTab: false, showfater: false}}]
                        },
                        {
                            path: 'UserPhotoTreat3', component: _import('personal_module/records/report'), name: 'UserPhotoTreat3', meta: {title: '其他报告', isTab: false, showfater: true},
                        },
                    ],
                },
        ],},

        {path: 'changeOrder', component: _import('doctor_station/remoteTreatment/orderChange'), name: 'changeOrder', meta: {title: '修改预约', isTab: false}},
        {path: 'broadcastBar', component: _import('personal_module/component/broadcastBar'), name: 'broadcastBar', meta: {title: '综合结论柱状图', isTab: false}},
        {path: 'HistorySingoHolter2Jump2', component: _import('personal_module/records/History/HistorySingoHolter2'), name: 'HistorySingoHolter2Jump2', meta: {title: '页面', isTab: false, showfater: false}},
        { path: 'report_query',component: _import('common/report_query'),name:'report_query',meta: {title: '报告查询' ,isTab: false },},
        { path: 'report_query_2', component: _import('common/report_query_2'), name: 'report_query_2', meta: { title: '具体报告查询', isTab: false},redirect: { name: 'ExamReport_query' },
            children:[{ path: 'HistorySingo2_query', component: _import('personal_module/records/History/HistorySingo2'), name: 'HistorySingo2_query', meta: { title: '页面', isTab: false, showfater: false }},
                        { path: 'ExamReport_query', component: _import('personal_module/records/report'), name: 'ExamReport_query', meta: { title: '综合体检报告', isTab: true } }]},
        { path: 'regist_ps2hs',component: _import('common/regist_ps2hs'),name:'regist_ps2hs',meta: {title: '个人用户注册' ,isTab: false }},
        { path: 'TiJianChaXun', component: _import('common/TiJianChaXun'), name:'TiJianChaXun', meta: {title: '快速查询', isTab: false }},
        { path: 'regist_gongdi',component: _import('common/regist_gongdi'),name:'regist_gongdi',meta: {title: '临港集团健康检测用户注册' ,isTab: false }},
        { path: 'regist_nursing_home', component: _import('common/regist_nursing_home'), name: 'regist_nursing_home', meta: { title: '养老院用户注册' ,isTab: false} },
        
    ]
},
]