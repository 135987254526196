/**
 * 全站路由配置
 *
 * 建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import Vue from 'vue'
import Router from 'vue-router'
import { clearLoginInfo } from '@/utils'
// import example from './example_module'
import example1 from './example_module1'
import station from './health_station'
import personal from './personal_module'
import community from './community_station'
import XingouDoctor from './doctor_station'
import remoteTreat from './remote_treatment'
import WomenBaby from './women_baby'
import across_plat_jump from './across_plat_jump'
import across_plat_jump_with_frame from './across_plat_jump_with_frame'
import older_station from './older_station'
import home_older from './home_older'
Vue.use(Router)

// var example_module = example[0]
var example_module1 = example1[0]
var health_station = station[0]
var personal_module = personal[0]
personal_module['beforeEnter'] = function (to, from, next) {
  let token = Vue.cookie.get('token')
  if (to== 'Invite'){
    next()
  }
  else if (!token || !/\S/.test(token)) {
    clearLoginInfo()
    next({ name: 'login' })
  }
  next()
}
var community_station =community[0]
var doctor_station =XingouDoctor[0]
var remote_treatment=remoteTreat[0]
remote_treatment['beforeEnter'] = function (to, from, next) {
  let token = Vue.cookie.get('token')
  if (to== 'Invite'){
    next()
  }
  else if (!token || !/\S/.test(token)) {
    clearLoginInfo()
    next({ name: 'login' })
  }
  next()
}
// example_module['beforeEnter'] = function (to, from, next) {
//   let token = Vue.cookie.get('token')
//   if (!token || !/\S/.test(token)) {
//     clearLoginInfo()
//     next({ name: 'login' })
//   }
//   next()
// }
var women_baby = WomenBaby[0]
// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require('./import-' + process.env.NODE_ENV)

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  {path: '/', component: _import('index'), name:'index', meta: {title:'平台首页',isTab: false}},
  { path: '/404', component: _import('common/404'), name: '404', meta: { title: '404未找到' ,isTab: false} },
  { path: '/login', component: _import('common/login'), name: 'login', meta: { title: '登录' ,isTab: false} },
  { path: '/regist', component: _import('common/regist'), name: 'regist', meta: { title: '注册' ,isTab: false} },
  { path: '/remote_treatment_doc', component: _import('remote_treatment/remote_treatment_doc'), name:'docTreat',meta: {title: '远程诊疗' ,isTab: false }},
  { path: '/invite',component: _import('remote_treatment/Invite'),name:'Invite',meta: {title: '远程邀请' ,isTab: false }},
  { path: '/MainHistorySingo2',component: _import('report/MainHistorySingo2'),name:'MainHistorySingo2',meta: {title: '单导联' ,isTab: false }},
  { path: '/regist_ps2hs',component: _import('common/regist_ps2hs'),name:'regist_ps2hs',meta: {title: '个人用户注册' ,isTab: false }},
  { path: '/regist_gongdi',component: _import('common/regist_gongdi'),name:'regist_gongdi',meta: {title: '临港集团健康检测用户注册' ,isTab: false }},
  { path: '/TiJianChaXun',component: _import('common/TiJianChaXun'),name:'TiJianChaXun',meta: {title: '快速查询' ,isTab: false }},
  { path: '/regist_nursing_home', component: _import('common/regist_nursing_home'), name: 'regist_nursing_home', meta: { title: '养老院用户注册' ,isTab: false} },
  { path: '/query_examine', component: _import('common/query_examine'), name: 'query_examine', meta: { title: '健康体检查询' ,isTab: false} },
  { path: '/regist_caohejing', component: _import('common/regist_caohejing'), name: 'regist_caohejing', meta: { title: '漕河泾物业公司用户注册' ,isTab: false} },
  { path: '/sancedan', component: _import('personal_module/records/SanCeDan/chartView'), name: 'sancedan', meta: { title: '三测单' ,isTab: false} },
  { path: '/older_report', component: _import('older_station/evaluation_report/evaluationReport'), name: 'older_report', meta: { title: '老人评估表' ,isTab: false} },
  { path: '/regist_home_older', component: _import('common/regist_home_older'), name: 'regist_home_older', meta: { title: '居家养老用户注册' ,isTab: false} },

]
// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = [
  // example_module,
  example_module1,
  health_station,
  personal_module,
  community_station,
  doctor_station,
  remote_treatment,
  across_plat_jump[0],
  across_plat_jump_with_frame[0],
  women_baby,
  older_station[0],
  home_older[0]
  // beforeEnter (to, from, next) {
  //   let token = Vue.cookie.get('token')
  //   if (!token || !/\S/.test(token)) {
  //     clearLoginInfo()
  //     next({ name: 'login' })
  //   }
  //   next()
  // }
]
console.log(mainRoutes)
const router = new Router({
  // mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: globalRoutes.concat(mainRoutes)
})





export default router
